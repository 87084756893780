#skills{
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    /* padding: 4rem; */
    padding-bottom: 2rem;
}
.skillTitle{
    font-size: 3rem;    
    font-weight: 600;
    padding-top: .5rem;
    margin-bottom: 1.5rem;

}
.skillDesc{
    font-weight: 300;
    font-size: 1rem;
    max-width: 50rem;
    padding: 0 2rem;

}
.skillBars{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;

}
.skillBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;   
    border-radius: 0.5rem;
    background: rgb(50, 50, 50);
}

.skillBarImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;

}
.skillBarText>p{
    font-size: 1rem;
    font-weight: 200;
}

@media screen and (max-width:720px) {
    .skillBarText>P{
        font-size: 2vw;
    }
}
@media screen and (max-width:480px) {
    .skillBarText>P{
        font-size: 3vw;
        display: flex;
        
    }
    .skillBarImg{
        height: 1.25rem;
        width: 1.25rem;
        /* display: flex; */
    }
    .skillBarText>h2{
        font-size: 5vw;
    }
    .skillBars{
        max-width: fit-content;
    }
    
}
