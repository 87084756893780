#contactPage{
    min-height: calc(100vh-4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#contactMePageTitle{
    font-size: 3rem;    
    font-weight: 600;
    padding-top: .5rem;
    margin-bottom: 1.5rem;
}
.contactDesc{
    padding: 1rem;
    font-size: medium;
    font-weight: 300;

}
.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;

}
.name, .email, .msg{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40,40,40);

}
.msg{
    resize: none;
}

.submitBtn{
    background: white;
    color: black;
    padding:0.75rem 3.5rem ;
    border: none;
    margin: 2rem;
    border-radius: 1.5rem;
    transition: 0.4s all;     
}
.submitBtn:hover{
    cursor: pointer;
    color: red;
    
    box-shadow: 0px 5px 15px rgba(214, 0, 28, 0.8); 
}
.submitBtn:active { 
    transform: scale(0.98); 
    /* Scaling button to 0.98 to its original size */ 
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
    /* Lowering the shadow */ 
}
.links{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    

}

.link{
    display: flex;
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
}
.git{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
    
}
.link:hover{
    cursor: pointer;
}
.git:hover{
    cursor: pointer;
}