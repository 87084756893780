#expertise{
    overflow: hidden;
    min-height: calc(100vh-4rem);
    width: 100vw;
    max-width: 65rem;
    margin: 0 auto;
    padding-bottom: 4rem;
    min-height: 100vh;
}
.mainTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom:3rem ;
    font-size: 3rem;
}
.toolImage{
    float: left;
    height: 5rem;
    padding: .5rem;
    margin-top: 4rem; 
    filter: grayscale(100);
}
.toolImage:hover{
    filter: grayscale(0);
}
.toolTitle{
    padding-bottom: 0rem;
}
.tools{
    float: left;
    width: 50%;
    /* display: flex; */
    padding-left: 2rem;
}
.barsTitle{
    padding-bottom: 3rem;   
}
.progressBar { 
    float: right;
  width: 50%;
  padding-left: 10rem;
  
}
.chart {
    background: rgba(255, 255, 255, 0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 20px;
    width: 300px;
}
.chart span {
    /* You can modify the value below to change the distance between the percentage number and the bar */
    margin-left: 5px;
    color: #fff;
    font-weight: bolder;
}
.jsBar, .reactBar, .sqlBar, .htmlCss {
    /* You can modify the total time used for the animation here */
    /* 
    Add a little spice by having a shadow below the bar.
    Feel free to comment out this line below to have an even LITE version :D 
    */
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #fff;
    height: 10px;
    width: 0;
}
.jsBar{
    animation: jsLoad 3s normal forwards;
}
@keyframes jsLoad {
    0% {
        width: 0;
    }
    100% {
        /* You need to change the percentage below to match the value in the corresponding <span> */
        width: 70%;
    }
}
.reactBar{
    animation: reactLoad 3s normal forwards;
}
@keyframes reactLoad {
    0% {
        width: 0;
    }
    100% {
        /* You need to change the percentage below to match the value in the corresponding <span> */
        width: 60%;
    }
}
.sqlBar{
    animation: sqlLoad 3s normal forwards;
}
@keyframes sqlLoad {
    0% {
        width: 0;
    }
    100% {
        /* You need to change the percentage below to match the value in the corresponding <span> */
        width: 50%;
    }

}
.htmlCss{
    animation: htmlCssLoad 3s normal forwards;
}
@keyframes htmlCssLoad {
    0% {
        width: 0;
    }
    100% {
        /* You need to change the percentage below to match the value in the corresponding <span> */
        width: 75%;
    }

}

@media screen and (max-width:964px) {
    #expertise{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        /* margin: auto; */
    }
   .tools{
    padding-bottom: 2rem;
    /* display: flex; */
    /* justify-content: center; */
    width: auto;
    padding-bottom: 4rem;
    
   }
   .progressBar{
    /* display: flex; */
    padding-left: 0%;
    width: auto;
   }
    
}