#works{
    min-height: calc(100vh-4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem 4rem;
}

.worksTitle{
    margin: 1rem 0;
    font-size: 3rem;

}
.worksDesc{
    font-weight: 300;
    font-size: 1rem;

}
.worksImgs{
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    width: 100vw;
    max-width: 65rem;
    

}

.workImg{
    object-fit: cover;
    height: 20rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    
}
.workImg:hover {
    
    opacity: .3 ;
    transition:opacity .5s ease;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/* @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  } */
  
  .project1{
    position: relative;
    
  }

.project1 .demo {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f5ab0c;
    color: white;
    font-size: 16px;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    
}

.details{
  display: none;
  position: absolute;
    top: 50%;
    right: 12%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f5ab0c;
    color: white;
    font-size: 16px;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}
.projectName{
  display: none;
}

.demo {
  display: none;
  
}
.project1:hover{
  .projectName{
    display: block;
  }
  .demo {
    display: block;
  }
  .details{
    display: block;
  }
}
.demo:hover{
  project1{
    filter: blur(20);
  }
}
.workBtn{
  display: none;
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background: white;
    font-size: 1rem;
}

@media screen and (max-width:585px) 
{
    .workImg{
        height: 48vw;
    }
    .project1:hover{
      .demo{
        left: 3rem;
      }
      .details{
        right: -.8rem;
      }
    }
    
}
/* @media screen and (max-width:1024px) 
{
  
  .project1:hover{
    .demo{
      position: relative;
      left: 3.5rem;
      font-size: 14px;
    }
    .details{
      position:relative;
      left: 9rem;
      top: -2.3rem;
      font-size: 14px;
    }
    
  }
  
} */