.navbar{
    height: 4rem;
    background:rgb(30, 30, 30  );
    /* background: radial-gradient(ellipse at bottom, #1b2735, #090a0f); */
    width:100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;

}
.logo{
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    

}
.logo:hover{
    cursor: pointer;
}
.desktopMenuListItem{
    margin: 1rem;
    cursor: pointer;


}
.desktopMenuListItem:hover{
    color: #FF3600 ;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #FF3600 ;
    
}
.desktopMenuListItem_contact{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-top: solid;
    border-bottom: solid; */
    padding: .4rem;
    /* border-radius: .5rem; */

    margin: 1rem;
    cursor: pointer;

}
.desktopMenuListItem_contact:hover{
    color: red;
    border-top: solid;
    border-bottom: solid;
    transform:translateY(2px) ;
}
.desktopMenuBtn{
    background: rgb(30, 30, 30  );
    color: white;
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: .5rem;
    font-size: smaller;
    
    /* font-weight:bold; */
    

}
.desktopMenuBtn:hover{
    color: red;
}
.desktopMenuImg:hover{
    cursor: pointer;
}

.desktopMenuImg{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem;

}
.active{
    color:red;
}
.moMenu{
    display: none;
    object-fit: cover;
    height: 1.8rem;

}
.moMenu:hover{
    cursor: pointer;
}
.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40,40,40);
    border-radius: 1rem;

}
.listItem{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30  );
}

@media screen and (max-width:720px) {
    .moMenu{
        display: flex;
    }
    .desktopMenu{
        display: none;

    }
    .desktopMenuBtn{
        display: none;
    }
}