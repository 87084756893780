

#intro{
    height: calc(100vh-4rem);
    width: 100vw;
    max-width: 75rem;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;   
    -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.bg{
    object-fit: cover;
   height: 40vh;
   justify-content: center;
   
   
}
.introContent{
    height: 100vh;
    text-align: center;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content:center;

}
.hello{
    font-size: 1.75rem;
    font-weight: 100;

}
.introName{
    /* color: red; */
    /* -webkit-animation: tracking-in-expand 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 5s infinite both;
	        animation: tracking-in-expand 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 5s infinite both; */
          
            -webkit-animation: tracking-in-expand 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2s infinite alternate-reverse both;
                    animation: tracking-in-expand 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2s infinite alternate-reverse both;
          
        }
        @-webkit-keyframes tracking-in-expand {
          0% {
            letter-spacing: -0.5em;
            opacity: 0;
          }
          40% {
            opacity: 0.6;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes tracking-in-expand {
          0% {
            letter-spacing: -0.5em;
            opacity: 0;
          }
          40% {
            opacity: 0.6;
          }
          100% {
            opacity: 1;
          }
        }
        
/* @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  } */
  
.introPara{
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
}

.btn{
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
    background: none;
    font-size: 1rem;
    color: white;
    margin: 2rem 0;
    padding: 0.75rem 1.5rem;
    border:none ;
    border-radius: 2rem;
    transition: 0.2s all;   
    border: white solid;
}
.btn:active { 
    transform: scale(0.98); 
    /* Scaling button to 0.98 to its original size */ 
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
    /* Lowering the shadow */ 
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.btn:hover{
    cursor: pointer;
    color: #FF3600 ;
    border:#FF3600  solid;
    
    box-shadow: 0px 5px 15px rgba(214, 0, 28, 0.8);
    /* -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both; */
          
            -webkit-animation: jello-horizontal 0.9s both;
                    animation: jello-horizontal 0.9s both;
    
}


.btnImg{
    object-fit: cover;
    
    margin: 0 .25rem;
    height: 4rem;
    
}
.arrow{
    height: 1.8vh;
    padding-right: .75rem;
    filter: grayscale(0);
    float: left;
    margin-top: .5rem;
    

    
}

@media screen and (max-width:900px) 
{
       
    .introContent{
        padding-top: 0% ;
        /* justify-content:flex-start; */
        min-height: 100svh; 
    }
    #intro{
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100svh;
    }
    .arrow{
      height: 1.5vh;

    }
    
    .introName{
      font-size: 2;
      /* animation: none; */
      
    }
    #intro{
      height: 75vh;
    }
    

}


    
        
    
    


